import * as Sentry from "@sentry/react";
import React from "react";
import { createBrowserRouter, Navigate, Outlet } from "react-router-dom";
import { pathAuthCallback, pathSignIn } from "./auth/authConst";
import AdminAuthorized from "./components/AdminAuthorized";
import { CriticalErrorFallback } from "./components/CriticalErrorBoundary";
import CustomerCreationAuthorized from "./components/CustomerCreationAuthorized";
import { trimLeadingSlash } from "./utils/urlUtils";
import AdminListingView from "./views/AdminView/AdminListingView";
import AuthCallbackView from "./views/AuthCallbackView";
import { Authenticated } from "./views/Authenticated";
import AuthSignInView from "./views/AuthSignInView";
import MatkontoCallbackView, {
    matkontoCallbackPath,
} from "./views/CalbackViews/MatkontoCallbackView";
import CustomerDetailsView from "./views/CustomerView/CustomerDetailsView";
import CustomerListingView from "./views/CustomerView/CustomerListingView";
import NewCustomerView from "./views/CustomerView/NewCustomerView";
import Layout from "./views/Layout";
import { navigationItems } from "./views/navigationItems";
import CreateOrdererView from "./views/OrdererView/CreateOrdererView";
import EditOrdererView from "./views/OrdererView/EditOrdererView";
import OrdererListingView from "./views/OrdererView/OrdererListingView";
import CreateUnitView from "./views/UnitView/CreateUnitView";
import UnitDetailsView from "./views/UnitView/UnitDetailsView";
import UnitListingView from "./views/UnitView/UnitListingView";

const sentryCreateBrowserRouter =
    Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter([
    {
        //https://github.com/getsentry/sentry-javascript/discussions/6912#discussioncomment-8792266
        element: (
            <Sentry.ErrorBoundary fallback={CriticalErrorFallback}>
                <Outlet />
            </Sentry.ErrorBoundary>
        ),
        children: [
            {
                path: pathSignIn,
                element: <AuthSignInView />,
            },
            {
                path: pathAuthCallback,
                element: <AuthCallbackView />,
            },
            {
                path: "*",
                element: (
                    <Authenticated>
                        <Layout />
                    </Authenticated>
                ),
                children: [
                    {
                        path: `${trimLeadingSlash(navigationItems[0].path)}`,
                        children: [
                            {
                                index: true,
                                element: <CustomerListingView />,
                            },
                            {
                                path: "skapa-ny",
                                element: (
                                    <CustomerCreationAuthorized>
                                        <NewCustomerView />
                                    </CustomerCreationAuthorized>
                                ),
                            },
                            {
                                path: ":customerId",
                                element: <CustomerDetailsView />,
                            },
                        ],
                    },
                    {
                        path: trimLeadingSlash(navigationItems[1].path),
                        element: (
                            <AdminAuthorized>
                                <Outlet />
                            </AdminAuthorized>
                        ),
                        children: [
                            {
                                index: true,
                                element: <OrdererListingView />,
                            },
                            {
                                path: "skapa-ny",
                                element: <CreateOrdererView />,
                            },
                            {
                                path: ":ordererId",
                                element: <EditOrdererView />,
                            },
                        ],
                    },
                    {
                        path: trimLeadingSlash(navigationItems[2].path),
                        element: (
                            <AdminAuthorized>
                                <Outlet />
                            </AdminAuthorized>
                        ),
                        children: [
                            {
                                index: true,
                                element: <UnitListingView />,
                            },
                            {
                                path: "skapa-ny",
                                element: <CreateUnitView />,
                            },
                            {
                                path: ":unitId",
                                element: <UnitDetailsView />,
                            },
                        ],
                    },
                    {
                        path: trimLeadingSlash(navigationItems[3].path),
                        element: (
                            <AdminAuthorized>
                                <Outlet />
                            </AdminAuthorized>
                        ),
                        children: [
                            {
                                index: true,
                                element: <AdminListingView />,
                            },
                        ],
                    },
                    {
                        path: trimLeadingSlash(matkontoCallbackPath),
                        element: <MatkontoCallbackView />,
                    },
                    {
                        path: "*",
                        element: <Navigate to={navigationItems[0].path} />,
                    },
                ],
            },
        ],
    },
]);
