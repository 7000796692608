const municipalityKey = (municipalityCode?: string) => [
    "municipality",
    municipalityCode,
];

const unitKey = (unitId?: string) => ["unit", unitId];

export const queryKeys = {
    customer: {
        full: ["customers"],
        byId: (id: string | undefined) => ["customers", id],
        all: (
            page: number,
            pageSize: number,
            unitIds?: string[],
            searchQuery?: string
        ) => ["customers", { units: unitIds, page, pageSize, searchQuery }],
    },
    administrators: {
        all: ["administrators"],
        byId: (id?: string) => ["administrators", id],
    },
    orderer: {
        all: ["orderers"],
        byId: (id?: string) => ["orderers", id],
    },
    unit: {
        all: (municipalityCode?: string) => [
            ...municipalityKey(municipalityCode),
            ...unitKey("all"),
        ],
        byId: (municipalityCode?: string, unitId?: string) => [
            ...municipalityKey(municipalityCode),
            ...unitKey(unitId),
        ],
        restrictions: (
            municipalityCode: string | undefined,
            unitId?: string
        ) => [...queryKeys.unit.byId(municipalityCode, unitId), "restrictions"],
        replacements: (
            municipalityCode: string | undefined,
            unitId?: string
        ) => [...queryKeys.unit.byId(municipalityCode, unitId), "replacements"],
    },
    municipality: {
        availablePaymentMethods: (municipalityCode?: string) => [
            ...municipalityKey(municipalityCode),
            "availablePaymentMethods",
        ],
        restrictions: (municipalityCode?: string) => [
            ...municipalityKey(municipalityCode),
            "restrictions",
        ],
    },
};
